<template>
  <div class="connect-wrapper wrapper pageBox">
    <div class="banner">
      <div class="banner-text">
        <div class="title">联系我们</div>
        <div class="sub">
          <div class="first">中嘉数科</div>
          <div>星辰大海，等你前来</div>
        </div>
        <div class="tip">
          医院更智慧  管理更及时  服务更贴心
        </div>
      </div>
    </div>
    <!-- <div class="banner">
      <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/banner.png">
      <div class="bannerText">
        <div class="title">联系我们</div>
        <div class="btn"><div class="company">中嘉数科</div><div class="colLine" />星辰大海，等你前来</div>
      </div>
    </div> -->
    <div class="connect">
      <div class="topText">
        <div class="title">联系方式</div>
        <div class="sub">CONTACT  US</div>
      </div>
      <div class="conBox">
        <div class="leftText">
          <div class="li">
            <div class="label">联系电话</div>
            <div class="value">
              <img src="@/assets/about/phone.svg">
              <span>0571-82650015</span>
            </div>
          </div>
          <div class="li">
            <div class="label">商务咨询</div>
            <div class="value">
              <img src="@/assets/about/phone.svg">
              <span>0571-82650015</span>
            </div>
          </div>
          <div class="li">
            <div class="label">杭州总部</div>
            <div class="value">
              <img src="@/assets/about/address.svg">
              <span>浙江省杭州市萧山区经济技术开发区明星路371号2幢902-3室</span>
            </div>
          </div>
          <div class="li">
            <div class="label">鹰潭办事处</div>
            <div class="value">
              <img src="@/assets/about/address.svg">
              <span>江西省鹰潭市月湖区沿江大道7号清波雅苑</span>
            </div>
          </div>
          <div class="li">
            <div class="label">赣州办事处</div>
            <div class="value">
              <img src="@/assets/about/address.svg">
              <span>江西省赣州市章贡区水南镇长征大道31号商会大厦</span>
            </div>
          </div>
          <div class="li">
            <div class="label">联系邮箱</div>
            <div class="value">
              <img src="@/assets/about/email.svg">
              <span>info@zhongjiadata.com</span>
            </div>
          </div>
        </div>
        <div class="mapBox">
          <div id="container" />
          <div class="tips">杭州总部地图</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let map;
export default {
  data() {
    return {
      icon: require('../../assets/about/mark_b.png')
    };
  },
  mounted() {
    this.mapInit();
  },
  methods: {
    mapInit() {
      // eslint-disable-next-line
      map = new AMap.Map('container', {
        zoom: 18,
        zooms: [1, 18],
        center: [120.247471, 30.203608]
      });
      // eslint-disable-next-line
      const marker = new AMap.Marker ({
        // eslint-disable-next-line
        position: new AMap.LngLat(120.248812, 30.203538),
        map: map
      });
      map.add(marker);
    }
  }
};
</script>

<style lang="scss" scoped>
.connect-wrapper {
  .banner {
    width: 100%;
    height: 720px;
    background: url('https://static.zhongjiadata.com/images/20250227/5eb6157d51bd4077a47b1c52bbe93497.png') no-repeat center center;
    background-size: cover;
    .banner-text {
      padding-top: 240px;
      width: 1384px;
      margin: 0 auto;
      .title {
        font-weight: 600;
        color: #fff;
        line-height: 84px;
        font-size: 60px;
      }
      .sub {
        width: 380px;
        height: 48px;
        background: #ffffff;
        border-radius: 7px;
        color: #418bdb;
        font-size: 24px;
        display: flex;
        align-items: center;
        padding-left: 14px;
        font-weight: 600;
        margin-bottom: 12px;
        .first {
          padding-right: 15px;
          margin-right: 15px;
          height: 34px;
          border-right: 1px solid #438ada;
        }
      }
      .tip {
        color: #ffffff;
        line-height: 27px;
        font-size: 20px;
      }
      .btn {
        width: 229px;
        height: 48px;
        background: #3873ff;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
}
.btn {
  width: 268px !important;
  background-color: rgba(255, 255, 255, .15)!important;
  .company {
    color: #2C6EFF;
  }
}
.topText {
  height: auto;
  color: #333;
  margin-bottom: 40px;
  .title {
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
  }
  .sub {
    line-height: 26px;
    font-size: 26px;
  }
}
.conBox {
  margin: 0 auto;
  margin-bottom: 288px;
  // margin-top: 50px;
  width: 1280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .leftText {
    flex: 1;
    // margin-top: 46px;
    .li {
      .label {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .value {
        font-size: 14px;
        margin-bottom: 40px;
        height: 22px;
        display: flex;
        align-items: center;
        color: #333;
        img {
          margin-right: 6px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .mapBox {
    margin-top: 7px;
    width: 800px;
    height: 600px;
    #container {
      width: 100%;
      height: 100%;
    }
    .tips {
      margin-top: 8px;
      font-size: 12px;
      color: #C8C8C8;
    }
  }
}
</style>
